/*-----------------------------------*\
  #CTA
\*-----------------------------------*/

.cta {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    text-align: center;
    overflow: hidden;
}

.cta::before {
    bottom: auto;
    top: 0;
    background-image: url("/public/images/shape-grey.png");
    transform: scaleY(-1);
}

.cta .section-title {
    color: var(--white);
    line-height: 1.5;
    letter-spacing: -2px;
    max-width: 17ch;
    margin-inline: auto;
}

.cta .section-text {
    color: var(--gainsboro);
    margin-block: 25px 20px;
}

.cta .btn {
    margin-inline: auto;
}

.cta-banner {
    position: relative;
    aspect-ratio: 1 / 0.9;
}

.cta-img {
    transform: scale(1.3) translateY(60px);
}

.cta-banner .abs-img {
    top: 50px;
    left: 10px;
}