@import url('https://fonts.googleapis.com/css2?family=Faster+One&family=Montserrat:wght@400;500;600;700;800;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Shadows+Into+Light&display=swap');

:root {

  /**
   * colors
   */

  --rich-black-fogra-29: hsl(210, 26%, 7%);
  --champagne-pink_20: hsla(23, 61%, 86%, 0.2);
  --independence_30: hsla(245, 17%, 29%, 0.3);
  --gray-x-11-gray: hsl(0, 0%, 73%);
  --champagne-pink: hsl(23, 61%, 86%);
  --spanish-gray: hsl(0, 0%, 60%);
  --sonic-silver: hsl(0, 0%, 47%);
  --deep-saffron: hsl(32, 100%, 59%);
  --dark-orange: hsl(28, 100%, 58%);
  --desert-sand: hsl(23, 49%, 82%);
  --isabelline: hsl(38, 44%, 96%);
  --gainsboro: hsl(0, 0%, 87%);
  --tangerine: hsl(31, 84%, 50%);
  --cinnabar: hsl(3, 90%, 55%);
  --black_95: hsla(0, 0%, 0%, 0.95);
  --cultured: hsl(0, 0%, 93%);
  --white: hsl(0, 0%, 100%);
  --black: hsl(0, 0%, 0%);
  --onyx: hsl(0, 0%, 27%);

  /**
   * typography
   */

  --ff-shadows-into-light: 'Shadows Into Light', cursive;
  --ff-roboto: 'Roboto', sans-serif;
  --ff-rubik: 'Rubik', sans-serif;

  --fs-1: 3.2rem;
  --fs-2: 2.2rem;
  --fs-3: 1.8rem;
  --fs-4: 1.4rem;
  --fs-5: 1.2rem;

  --fw-500: 500;
  --fw-600: 600;
  --fw-700: 700;

  /**
   * spacing
   */

  --section-padding: 60px;

  /**
   * shadow
   */

  --shadow-1: 0 1px 4px hsla(0, 0%, 0%, 0.2);
  --shadow-2: 0 1px 2px hsla(0, 0%, 0%, 0.2);

  /**
   * transition
   */

  --transition-1: 0.25s ease;
  --transition-2: 0.5s ease;

  /**
   * clip path
   */

  --clip-path-1: polygon(0 40%, 100% 0%, 100% 100%, 0 100%);
  --clip-path-2: polygon(0 0%, 100% 0%, 100% 100%, 0 100%);

}





/*-----------------------------------*\
  #RESET
\*-----------------------------------*/

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

a,
img,
svg,
span,
input,
select,
button,
textarea,
ion-icon {
  display: block;
}

img {
  height: auto;
}

input,
select,
button,
textarea {
  background: none;
  border: none;
  font: inherit;
}

input,
select,
textarea {
  width: 100%;
}

button {
  cursor: pointer;
}

ion-icon {
  pointer-events: none;
}

address {
  font-style: normal;
}

html {
  font-family: var(--ff-roboto);
  font-size: 10px;
  scroll-behavior: smooth;
}

body {
  background-color: var(--white);
  color: var(--sonic-silver);
  font-size: 1.6rem;
  line-height: 1.6;
  overflow-x: hidden;
}

:focus-visible {
  outline-offset: 4px;
}

::selection {
  background-color: var(--deep-saffron);
  color: var(--white);
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background-color: var(--cultured);
}

::-webkit-scrollbar-thumb {
  background-color: var(--deep-saffron);
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--tangerine);
}





/*-----------------------------------*\
  #REUSED STYLE
\*-----------------------------------*/

.container {
  padding-inline: 15px;
}

.h1,
.h2,
.h3,
.h4 {
  font-family: var(--ff-rubik);
  color: var(--rich-black-fogra-29);
  line-height: 1.2;
  letter-spacing: -1px;
}

.h1,
.h2 {
  font-size: var(--fs-1);
}

.h2,
.h3,
.h4 {
  font-weight: var(--fw-600);
}

.h3 {
  font-size: var(--fs-2);
}

.h4 {
  font-size: var(--fs-3);
}

.btn {
  background-color: var(--bg-color, var(--deep-saffron));
  color: var(--white);
  font-family: var(--ff-rubik);
  font-size: var(--fs-4);
  font-weight: var(--fw-500);
  height: var(--height, 45px);
  padding-inline: var(--padding-inline, 35px);
  transition: var(--transition-1);
}

.btn-hover {
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.btn-hover::after {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 20px;
  width: 1px;
  height: 1px;
  transform: translate(-50%, 51%) scale(var(--scale, 1));
  border-radius: 50%;
  background-color: var(--black);
  color: var(--white) !important;
  z-index: -1;
  transition: var(--transition-2);
}

.btn-hover:is(:hover, :focus)::after {
  --scale: 500;
}

.section {
  padding-block: var(--section-padding);
}

.section.white {
  background-color: var(--isabelline);
}

.section-divider {
  position: relative;
}

.section-divider::before,
.section-divider::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 15px;
  background-repeat: repeat no-repeat;
  background-position: bottom;
}

.rating-wrapper {
  display: flex;
  gap: 5px;
  color: var(--deep-saffron);
}

.delivery-img {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-80px);
  transition: var(--transition-2);
}



.w-100 {
  width: 100%;
}

.has-scrollbar {
  display: flex;
  gap: 10px;
  overflow-x: auto;
  scroll-snap-type: inline mandatory;
  padding-block-end: 40px;
}

.has-scrollbar::-webkit-scrollbar {
  height: 12px;
}

.has-scrollbar::-webkit-scrollbar-button {
  width: calc(25% - 40px);
}

.has-scrollbar::-webkit-scrollbar-track {
  outline: 2px solid var(--deep-saffron);
  border-radius: 50px;
}

.has-scrollbar::-webkit-scrollbar-thumb {
  border: 3px solid var(--cultured);
  border-radius: 50px;
}

.section-title>.span {
  display: inline-block;
  color: var(--deep-saffron);
}

.section-subtitle {
  color: var(--cinnabar);
  font-family: var(--ff-rubik);
  text-align: center;
  font-weight: var(--fw-500);
}



/*-----------------------------------*\
  #MEDIA QUERIES
\*-----------------------------------*/

/**
 * responsive for larger than 480px screen
 */

 @media (min-width: 480px) {

  /**
   * HEADER
   */

  .header-btn-group .btn {
    display: block;
    --bg-color: var(--dark-orange);
  }
  
}





/**
 * responsive for larger than 550px screen
 */

@media (min-width: 550px) {

  /**
   * REUSED STYLE
   */

  .container {
    max-width: 550px;
    width: 100%;
    margin-inline: auto;
  }

  .has-scrollbar > li { min-width: calc(50% - 5px); }



  /**
   * HERO
   */

  .hero-title { --fs-1: 4.2rem; }



  /**
   * FOOTER
   */

  .footer-top .container { grid-template-columns: 1fr 1fr; }

}





/**
 * responsive for larger than 768px screen
 */

@media (min-width: 768px) {

  /**
   * REUSED STYLE
   */

  .container { max-width: 720px; }



  /**
   * HERO
   */

  .hero {
    position: relative;
    text-align: left;
    overflow: hidden;
    z-index: 1;
  }

  .hero-content > :is(*, .btn) { margin-inline: 0; }

  .hero-banner {
    display: block;
    position: absolute;
    top: calc(50% + 86px);
    transform: translateY(-50%);
    right: 50px;
    max-width: 40%;
    aspect-ratio: 1 / 0.9;
  }

  .hero-img {
    position: absolute;
    bottom: 0;
  }

  .hero::after {
    content: "";
    position: absolute;
    right: 0;
    bottom: -2px;
    width: 100%;
    height: 100%;
    background-image: url("/public/images/hero-bg-shape.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right bottom;
    pointer-events: none;
    z-index: -1;
  }



  /**
   * ABOUT
   */

  .about .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
  }

  .about { text-align: left; }

  .about .section-title { margin-block-start: 0; }

  .about :is(.section-title, .btn) { margin-inline: 0; }

  .about-item { justify-content: flex-start; }



  /**
   * FOOD MENU
   */

  .food-menu-list { grid-template-columns: 1fr 1fr; }

  .food-menu-card :is(.wrapper, .price-wrapper) { justify-content: flex-start; }

  .food-menu-card .card-title { text-align: left; }



  /**
   * CTA
   */

  .cta .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 30px;
  }

  .cta { text-align: left; }

  .cta :is(.section-title, .btn) { margin-inline: 0; }

  .cta-img { transform: scale(1.3) translate(90px, 20px); }



  /**
   * DELIVERY
   */

  .delivery .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 50px;
  }



  /**
   * BANNER
   */

  .banner-list { grid-template-columns: repeat(4, 1fr); }

  .banner-lg {
    grid-column: 1 / 3;
    grid-row: 1 / 3;
  }

  .banner-md {
    grid-column: 3 / 5;
    grid-row: 2 / 3;
    height: 220px;
  }



  /**
   * BLOG
   */

  .blog-list { grid-template-columns: 1fr 1fr; }

  .blog-card { height: 100%; }



  /**
   * FOOTER
   */

  .input-wrapper { grid-template-columns: 1fr 1fr; }

}





/**
 * responsive for larger than 992px screen
 */

@media (min-width: 992px) {

  /**
   * CUSTOM PROPERTY
   */

  :root {

    /**
     * spacing
     */

    --section-padding: 120px;

  }



  /**
   * REUSED STYLE
   */

  .container { max-width: 960px; }

  .has-scrollbar > li { min-width: calc(33.33% - 6.66px); }

  .h2 { --fs-1: 4.2rem; }



  /**
   * HEADER
   */

  .nav-toggle-btn { display: none; }

  .header .container { gap: 20px; }

  .navbar,
  .navbar.active {
    all: unset;
    margin-inline-start: auto;
  }

  .navbar-list {
    margin-block: 0;
    display: flex;
    gap: 5px;
  }

  .nav-item:not(:last-child) { border-block-end: none; }

  .navbar-link {
    --fs-4: 1.5rem;
    color: var(--btn-color);
    letter-spacing: -0.5px;
  }



  /**
   * HERO
   */

  .hero {
    min-height: 660px;
    display: grid;
    align-items: center;
  }

  .hero-subtitle { --fs-3: 3.2rem; }

  .hero-title {
    --fs-1: 7rem;
    letter-spacing: -2.5px;
  }

  .hero-text { font-size: var(--fs-3); }

  .hero-banner {
    max-width: 45%;
    top: auto;
    bottom: 0;
    transform: translateY(0);
  }

  .hero-img-bg { transform: scale(1.4) translate(20px, -20px); }



  /**
   * FOOD MENU
   */

  .food-menu-list { grid-template-columns: repeat(3, 1fr); }

  .food-menu-card { height: 100%; }



  /**
   * CTA
   */

  .cta-img { transform: scale(1.4) translate(20px, 40px); }

  .cta-banner .abs-img { left: -50px; }

  :is(.cta, .delivery) .section-title { line-height: 1.2; }



  /**
   * BANNER
   */

  .banner-lg .banner-title {
    --fs-1: 3.6rem;
    letter-spacing: -2px;
  }

  :is(.banner-md, .banner-sm) .banner-title,
  .banner-lg .banner-subtitle { --fs-2: 3.2rem; }



  /**
   * TESTIMONIALS
   */

  .testi-list { padding-block-end: 0; }



  /**
   * BLOG
   */

  .blog-list { grid-template-columns: repeat(3, 1fr); }

  .blog-card .card-title { --fs-3: 2.2rem; }



  /**
   * FOOTER
   */

  .footer-top .container { grid-template-columns: 1fr 1fr 1fr 1.6fr; }

  .footer-form { margin-block-start: -170px; }



  /**
   * BACK TO TOP
   */

  .back-top-btn {
    bottom: 20px;
    right: 30px;
  }

}





/**
 * responsive for larger than 1200px screen
 */

@media (min-width: 1200px) {

  /**
   * REUSED STYLE
   */

  .container { max-width: 1200px; }



  /**
   * HERO
   */

  .hero { min-height: 770px; }



  /**
   * PROMO
   */
  
  .promo .promo-item { min-width: calc(25% - 7.5px); }



  /**
   * ABOUT
   */

  .about .container { gap: 60px; }



  /**
   * CTA
   */

  .cta { --section-padding: 60px; }

  .cta .section-title { max-width: 18ch; }



  /**
   * BANNER
   */

  .banner-md .banner-item-content { left: 55%; }



  /**
   * FOOTER
   */

  .footer-top .container { grid-template-columns: 1.4fr 1fr 1fr 1.6fr; }

}