
/*-----------------------------------*\
  #HEADER
\*-----------------------------------*/

/* .navbar, */
.header-btn-group .btn { display: none; }

.header {
  --color: var(--white);
  --btn-color: var(--champagne-pink);

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-block: 20px;
  border-block-end: 1px solid var(--champagne-pink_20);
  z-index: 4;
}

.header.active {
  --color: var(--rich-black-fogra-29);
  --btn-color: var(--rich-black-fogra-29);

  position: fixed;
  top: -86px;
  background-color: var(--white);
  box-shadow: var(--shadow-1);
  animation: slideIn 0.5s ease-out forwards;
}

@keyframes slideIn {
  0% { transform: translateY(0); }
  100% { transform: translateY(100%); }
}

.header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  color: var(--color);
  font-family: var(--ff-rubik);
  font-size: 2.8rem;
  font-weight: var(--fw-700);
  letter-spacing: -2px;
}

.logo .span {
  display: inline-block;
  color: var(--deep-saffron);
}

.header-btn-group {
  display: flex;
  align-items: center;
  gap: 20px;
}

.search-btn {
  color: var(--btn-color);
  font-size: 20px;
}

.search-btn ion-icon { --ionicon-stroke-width: 50px; }

.nav-toggle-btn {
  display: grid;
  gap: 4px;
}

.line {
  width: 10px;
  height: 3px;
  background-color: var(--btn-color);
  border-radius: 5px;
  transition: var(--transition-1);
}

.line.middle { width: 20px; }

.line.bottom { margin-left: auto; }

.nav-toggle-btn.active .line.top { transform: translate(1px, 3px) rotate(45deg); }

.nav-toggle-btn.active .line.middle { transform: rotate(-45deg); }

.nav-toggle-btn.active .line.bottom { transform: translate(-1px, -3px) rotate(45deg); }

.navbar {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 30px);
  background-color: var(--white);
  padding-inline: 20px;
  box-shadow: var(--shadow-1);
  height: 0;
  overflow: hidden;
  visibility: hidden;
  transition: var(--transition-1);
}

.navbar.active {
  height: 236px;
  visibility: visible;
}

.navbar-list { margin-block: 10px; }

.nav-item:not(:last-child) { border-block-end: 1px solid hsla(0, 0%, 0%, 0.04); }

.navbar-link {
  color: var(--rich-black-fogra-29);
  font-size: var(--fs-4);
  font-family: var(--ff-rubik);
  font-weight: var(--fw-500);
  padding: 10px 15px;
  transition: var(--transition-1);
}

.navbar-link:is(:hover, :focus) { color: var(--dark-orange); }




/*-----------------------------------*\
  #SEARCH BOX
\*-----------------------------------*/

.search-container {
  position: fixed;
  top: -60%;
  left: 0;
  width: 100%;
  height: 110%;
  background-color: var(--black_95);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-inline: 15px;
  z-index: 5;
  visibility: hidden;
  opacity: 0;
  transition: var(--transition-2);
}

.search-container.active {
  visibility: visible;
  opacity: 1;
  transform: translateY(50%);
}

.search-box {
  position: relative;
  width: 100%;
  max-width: 500px;
}

.search-input {
  color: var(--gainsboro);
  font-size: 3rem;
  padding: 20px 15px;
  padding-inline-end: 70px;
}

.search-input::placeholder { color: var(--spanish-gray); }

.search-input::-webkit-search-cancel-button { display: none; }

.search-submit {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  color: var(--onyx);
  font-size: 4rem;
  transition: var(--transition-1);
}

.search-submit:is(:hover, :focus) { color: var(--gainsboro); }

.search-close-btn {
  position: absolute;
  inset: 0;
  z-index: -1;
  cursor: url("/public/images/close.png"), auto;
}



