/*-----------------------------------*\
  #BACK TO TOP
\*-----------------------------------*/

.back-top-btn {
    position: fixed;
    bottom: 10px;
    right: 20px;
    background-color: var(--deep-saffron);
    color: var(--white);
    padding: 15px;
    border-radius: 50%;
    box-shadow: var(--shadow-1);
    z-index: 2;
    opacity: 0;
    visibility: hidden;
    transition: var(--transition-1);
}

.back-top-btn.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(-10px);
}

.back-top-btn:is(:hover, :focus) {
    background-color: var(--rich-black-fogra-29);
}