/*-----------------------------------*\
  #HERO
\*-----------------------------------*/

.hero-banner { display: none; }

.hero {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding-block: 145px 60px;
  text-align: center;
}

.hero-subtitle {
  color: var(--dark-orange) !important;
  font-family: var(--ff-shadows-into-light);
  font-size: var(--fs-3);
  letter-spacing: 1px;
  margin-block-end: 25px;
}

.hero-title {
  color: var(--champagne-pink) !important;
  max-width: 12ch;
  margin-inline: auto;
}

.hero-text {
  color: var(--desert-sand) !important;
  margin-block: 15px 30px;
  max-width: 44ch;
  margin-inline: auto;
}

.hero .btn { margin-inline: auto; }

.hero .btn:is(:hover, :focus) {
  background-color: var(--white);
  color: var(--black);
}


