
/*-----------------------------------*\
  #TESTIMONIALS
\*-----------------------------------*/

.testi { text-align: center; }

.testi .section-title { margin-block: 10px 20px; }

.testi .section-text {
  max-width: 44ch;
  margin-inline: auto;
  margin-block-end: 30px;
}

.testi-card {
  background-color: var(--white);
  padding: 40px;
  text-align: left;
  box-shadow: var(--shadow-2);
}

.testi-card .profile-wrapper {
  display: flex;
  align-items: center;
  gap: 15px;
}

.testi-card .avatar {
  min-width: max-content;
  border-radius: 50%;
  overflow: hidden;
}

.testi-card .testi-name { margin-block-end: 2px; }

.testi-card .testi-title {
  font-family: var(--ff-rubik);
  font-size: var(--fs-4);
}

.testi-card .testi-text { margin-block: 20px 15px; }

.testi-item {
  min-width: 100%;
  scroll-snap-align: start;
}



