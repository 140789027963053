

/*-----------------------------------*\
  #BLOG
\*-----------------------------------*/

.blog { text-align: center; }

.blog .section-title { margin-block: 10px 20px; }

.blog .section-text {
  margin-block-end: 40px;
  max-width: 44ch;
  margin-inline: auto;
}

.blog-list {
  display: grid;
  gap: 30px;
}

.blog-card {
  background-color: var(--white);
  text-align: left;
  box-shadow: var(--shadow-2);
}

.blog-card .card-banner {
  position: relative;
  aspect-ratio: 1 / 0.65;
  background-color: var(--gainsboro);
}

.blog-card .badge {
  top: 20px;
  right: 20px;
  font-family: var(--ff-rubik);
  text-transform: uppercase;
  --bg-color: var(--tangerine);
  --padding-block: 5px;
}

.blog-card .card-content { padding: 30px; }

.blog-card .card-meta-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 12px 20px;
}

.blog-card .card-meta-link {
  display: flex;
  align-items: center;
  gap: 10px;
}

.blog-card .card-meta-link ion-icon {
  color: var(--tangerine);
  font-size: 1.5rem;
  --ionicon-stroke-width: 60px;
}

.blog-card :is(.meta-info, .btn-link) {
  color: var(--rich-black-fogra-29);
  font-family: var(--ff-rubik);
  font-size: var(--fs-5);
  font-weight: var(--fw-600);
  text-transform: uppercase;
  line-height: 1;
  transition: var(--transition-1);
}

.blog-card .card-meta-link:is(:hover, :focus) .meta-info {
  color: var(--deep-saffron);
}

.blog-card .card-title {
  font-size: var(--fs-3);
  margin-block: 20px 15px;
  transition: var(--transition-1);
}

.blog-card .card-title:is(:hover, :focus) {
  opacity: 0.8;
  text-decoration: underline;
}

.blog-card .card-text { margin-block-end: 20px; }

.blog-card .btn-link {
  display: flex;
  align-items: center;
  gap: 5px;
}

.blog-card .btn-link ion-icon { font-size: 1.5rem; }

.blog-card .btn-link:is(:hover, :focus) {
  color: var(--deep-saffron);
  gap: 10px;
}



