.food-menu {
    background-color: var(--isabelline);
    text-align: center;
  }
  
  .food-menu .section-title { margin-block: 10px 20px; }
  
  .food-menu .section-text {
    max-width: 44ch;
    margin-inline: auto;
    margin-block-end: 30px;
  }
  
  .fiter-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    margin-block-end: 40px;
  }
  
  .filter-btn {
    background-color: var(--white);
    color: var(--color, var(--rich-black-fogra-29));
    font-family: var(--ff-rubik);
    font-weight: var(--fw-500);
    padding: 5px 20px;
    border: 1px solid var(--border-color, var(--cultured));
  }
  
  .filter-btn.active {
    background-color: var(--deep-saffron);
    --color: var(--white);
    --border-color: var(--deep-saffron);
  }
  
  .food-menu-list {
    display: grid;
    gap: 30px;
  }
  
  .food-menu-card {
    background-color: var(--white);
    padding: 40px;
    box-shadow: var(--shadow-2);
  }
  
  .food-menu-card:focus-within { outline: 1px auto -webkit-focus-ring-color; }
  
  .food-menu-card .card-banner {
    position: relative;
    padding-block-start: 30px;
    max-width: max-content;
    aspect-ratio: 1 / 1;
    margin-inline: auto;
  }
  
  .food-menu-card .badge {
    top: 0;
    left: 0;
  }
  
  .food-menu-btn {
    position: absolute;
    top: calc(50% + 15px);
    left: 50%;
    transform: translate(-50%, 0);
    min-width: max-content;
    --bg-color: var(--cinnabar);
    width: 60%;
    --height: 40px;
    --padding-inline: 20px;
    opacity: 0;
    transition: var(--transition-2);
  }
  
  .food-menu-btn:is(:hover, :focus) { --bg-color: var(--deep-saffron); }
  
  .food-menu-card:is(:hover, :focus-within) .food-menu-btn {
    transform: translate(-50%, -50%);
    opacity: 1;
  }
  
  .food-menu-card .wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    margin-block: 20px 10px;
  }
  
  .food-menu-card .category {
    font-family: var(--ff-rubik);
    font-weight: var(--fw-500);
  }
  
  .food-menu-card .rating-wrapper { font-size: 1.4rem; }
  
  .food-menu-card .card-title { margin-block-end: 10px; }
  
  .food-menu-card .price-wrapper {
    display: flex;
    justify-content: center;
    gap: 5px;
    font-family: var(--ff-rubik);
    font-weight: var(--fw-600);
  }
  
  .food-menu-card .price-text {
    color: var(--cinnabar);
    text-transform: uppercase;
    padding-inline-end: 5px;
  }
  
  .food-menu-card .price { color: var(--deep-saffron); }
  
  .food-menu-card .del { color: var(--gray-x-11-gray); }
  
  